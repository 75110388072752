import React from 'react';
import styled from 'react-emotion';
import { Grid } from '@8base/boost';

const ContentTag = styled(Grid.Box)({
  padding: '2rem 2rem 2rem 2rem',
  minHeight: 0,
});

const Content = ({ children }) => <ContentTag>{children}</ContentTag>;

export { Content };
