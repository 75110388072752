import React from 'react';
import { Card, Heading } from '@8base/boost';

import { FeatureCreateForm } from './FeatureCreateForm';

const Features = ({ brokers, openModal, closeModal }) => (
  <Card padding="md" stretch>
    <Card.Header>
      <Heading type="h4" text="New Feature" />
    </Card.Header>

    <Card.Body stretch>
      <FeatureCreateForm />
    </Card.Body>
  </Card>
);

export { Features };
