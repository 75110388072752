import React from 'react';
import { Grid } from '@8base/boost';
import styled from 'react-emotion';

import { Header } from './Header';
import { Content } from './Content';

const MainTag = styled(Grid.Layout)`
  background-color: #f4f5f6;
  height: 100vh;
  flex: 1;
`;

const Main = ({ children }) => (
  <MainTag rows="60px 1fr" stretch>
    <Header />
    <Content>{children}</Content>
  </MainTag>
);

export { Main };
