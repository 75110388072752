import React from 'react';
import * as R from 'ramda';
import { Form, Field } from '@8base/forms';
import { Column, Row, Button, SelectField, InputField, CheckboxField, TextAreaField, Select } from '@8base/boost';
import { Query, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { TOAST_SUCCESS_MESSAGE, PROJECTS } from 'shared/constants';

const FEATURE_CREATE_MUTATION = gql`
  mutation FeatureCreate($data: FeatureCreateInput!) {
    featureCreate(data: $data) {
      summary
    }
  }
`;

const EPICS_QUERY = gql`
  query EpicsList {
    epicsList {
      items {
        id
        name
        key
      }
    }
  }
`;

const VERSIONS_QUERY = gql`
  query VersionsList {
    versionsList {
      items {
        id
        name
      }
    }
  }
`;
const PRIORITIES_LIST_QUERY = gql`
  query PrioritiesList {
    prioritiesList {
      items {
        name
        iconUrl
      }
    }
  }
`;

const getVersionOptions = R.pipe(
  R.pathOr([], ['versionsList', 'items']),
  R.map(({ name }) => ({ value: name, label: name })),
);

const getEpicOptions = R.pipe(
  R.pathOr([], ['epicsList', 'items']),
  R.map(({ name, key }) => ({ value: key, label: name })),
);

const getPriorityOptions = R.pipe(
  R.pathOr([], ['prioritiesList', 'items']),
  R.map(({ name, iconUrl }) => ({ value: name, label: name, iconUrl })),
);

const PriorityOption = ({ children, ...props}) => (
  <Select.components.Option {...props}>
    <Row alignItems="center">
      <img alt="" src={props.data.iconUrl} style={{ width: 16, height: 16 }} />
      <span>{children}</span>
    </Row>
  </Select.components.Option>
);

const PrioritySingleValue = ({ children, ...props}) => (
  <Select.components.SingleValue {...props}>
    <Row alignItems="center">
      <img alt="" src={props.data.iconUrl} style={{ width: 16, height: 16 }} />
      <span>{children}</span>
    </Row>
  </Select.components.SingleValue>
);

class FeatureCreateForm extends React.Component {
  onSubmit = async (data) => {
    data = {
      ...data,
      projects: Object.keys(data.projects || {}).reduce((result, key) => data.projects[key] ? [...result, key] : result, [])
    };

    await this.props.featureCreate({ variables: { data }});

    this.form.reset();
  };

  renderFormContent = ({ handleSubmit, invalid, submitting, pristine, form }) => {
    this.form = form;

    return (
      <form onSubmit={ handleSubmit } style={{ width: '100%' }}>
        <Column gap="lg">
          <Field name="summary" label="Summary" component={ InputField } />
          <Field name="description" label="Description" component={ TextAreaField } style={{ minHeight: 200 }} stretch />
          
          <Row>
            {
              React.Children.toArray(
                PROJECTS.map(
                  ({ id, name }, index) => <Field name={`projects.${id}`} label={name} component={ CheckboxField } nowrap />
                )
              )
            }
          </Row>

          <Query query={ VERSIONS_QUERY }>
            {
              ({ data, loading }) => (
                <Field
                  name="version"
                  label="Version"
                  component={ SelectField }
                  loading={ loading }
                  options={ loading ? [] : getVersionOptions(data) }
                  stretch
                />
              )
            }
          </Query>

          <Query query={ EPICS_QUERY }>
            {
              ({ data, loading }) => (
                <Field
                  name="epic"
                  label="Epic"
                  component={ SelectField }
                  loading={ loading }
                  options={ loading ? [] : getEpicOptions(data) }
                  stretch
                />
              )
            }
          </Query>
          <Query query={ PRIORITIES_LIST_QUERY }>
            {
              ({ data, loading }) => (
                <Field
                  name="priority"
                  label="Priority"
                  component={ SelectField }
                  components={{
                    Option: PriorityOption, 
                    SingleValue: PrioritySingleValue,
                  }}
                  loading={ loading }
                  options={ loading ? [] : getPriorityOptions(data) }
                  stretch
                />
              )
            }
          </Query>
          <Button color="primary" type="submit" size="lg" loading={ submitting }>Create Feature</Button>
        </Column>
      </form>
    );
  }

  render() {
    return (
      <Form onSubmit={ this.onSubmit }>
        { this.renderFormContent }
      </Form>
    );
  }
}

FeatureCreateForm = graphql(FEATURE_CREATE_MUTATION, {
  name: 'featureCreate',
  options: {
    context: {
      [TOAST_SUCCESS_MESSAGE]: 'Feature successfuly created'
    },
  },
})(FeatureCreateForm);

export { FeatureCreateForm };
